import {
  Layout,
  DescriptionList,
  InlineGroup,
} from '@vwfs-bronson/bronson-react';
import { financialFormat } from '../../../../helpers/financialFormat';
import { formatWithThousandsSeparator } from '../../../../helpers/formatWithThousandsSeparator';

export const LeasingInfo = ({ offer }) => {
  if (!offer) return null;

  return (
    <Layout className="row u-mb">
      <Layout.Item default="1/1">
        <InlineGroup bottom>
          <DescriptionList grid>
            <DescriptionList.Group termText="Campaña">
              <b>{offer.Campain?.Value}</b>
            </DescriptionList.Group>
          </DescriptionList>
          <DescriptionList grid>
            <DescriptionList.Group termText="Aplazamiento">
              <b>{offer.Months} Meses</b>
            </DescriptionList.Group>
          </DescriptionList>
          {offer.Km !== '' && (
            <DescriptionList grid>
              <DescriptionList.Group termText="Kilometraje Anual">
                <b>{formatWithThousandsSeparator(offer.Km, '.')} km</b>
              </DescriptionList.Group>
            </DescriptionList>
          )}
          <DescriptionList grid>
            <DescriptionList.Group termText="Primera mensualidad">
              <b>
                {parseInt(offer.InitAmount) > 0
                  ? financialFormat(offer.InitAmount) + ' € + IVA'
                  : '-'}
              </b>
            </DescriptionList.Group>
          </DescriptionList>
          <DescriptionList grid>
            <DescriptionList.Group termText="TIN">
              <b>{financialFormat(offer.PorcentajeTIN)} %</b>
            </DescriptionList.Group>
          </DescriptionList>
          <DescriptionList grid>
            <DescriptionList.Group termText="TAE">
              <b>{financialFormat(offer.PorcentajeTAE)} %</b>
            </DescriptionList.Group>
          </DescriptionList>
          <DescriptionList grid>
            <DescriptionList.Group termText="Comisión de Apertura">
              <b>{financialFormat(offer.ComisionApertura)} €</b>
            </DescriptionList.Group>
          </DescriptionList>
          <DescriptionList grid>
            <DescriptionList.Group termText="Importe del crédito">
              <b>{financialFormat(offer.ImporteTotalFinanciado)} €</b>
            </DescriptionList.Group>
          </DescriptionList>
          <DescriptionList grid>
            <DescriptionList.Group termText="Cuota mensual">
              <b>{financialFormat(offer.FeeAmount)} € + IVA</b>
            </DescriptionList.Group>
          </DescriptionList>
        </InlineGroup>
      </Layout.Item>
    </Layout>
  );
};
