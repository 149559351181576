import { useState } from 'react';
import {
  Modal,
  Spinner,
  Button,
  Layout,
  PriceBox,
  Card,
} from '@vwfs-bronson/bronson-react';
import { financialFormat } from '../../helpers/financialFormat';
import UtilitiesService from '../../services/UtilitiesService';
import { PRODUCT_CODE } from '../../types/types';

export const SearchOffersLeasing = ({
  offer,
  sendOfferToOperation,
  isLoadingOperation,
  generateCreditRequestDocument,
}) => {
  const [operationCode, setOperationCode] = useState(null);

  const sendToOperation = async () => {
    const status = await sendOfferToOperation(offer);

    if (status === 200) {
      generateCreditRequestDocument(null, offer.Code);
    }
  };

  const handleOnClick = () => {
    if (UtilitiesService.isRVO())
      window.open('./assets/pdfs/solicituddecredito_RE.pdf', '_blank');
    else window.open('./assets/pdfs/solicituddecredito_FI.pdf', '_blank');
  };

  return (
    <>
      <Layout center>
        <Layout.Item default="1/3" l="1/2" m="1/1">
          <Card>
            <Layout flush center>
              <Layout.Item>
                <h6 className="u-text-center">Código oferta</h6>
                <h4 className="u-text-center">{offer.Code}</h4>
              </Layout.Item>
              <Layout.Item>
                <div className="u-text-center">
                  {offer?.ProdFam?.Key !== 'FINA' && offer.Campain.Key + ' - '}
                  {offer.Campain.Value}
                </div>
                <PriceBox
                  ariaLabelPrice="Price:"
                  price={financialFormat(parseInt(offer.FeeAmount)) + ' €'}
                  additionalInfo={'mes + IVA'}
                />
                <div className="u-text-center">
                  {offer.Months} meses{' '}
                  {offer.Product.Key !== PRODUCT_CODE.LI &&
                    ` - ${financialFormat(offer.Km, 0)} km`}
                </div>
                <div className="u-text-center">
                  <label>Primera mensualidad</label>
                  <label>
                    {financialFormat(
                      offer.InitAmount > 0 ? offer.InitAmount : offer.FeeAmount,
                    ) + ' € + IVA'}
                  </label>
                </div>
                <p className="u-text-center">{offer.DesSituacion}</p>
              </Layout.Item>
              <Layout.Item className="u-text-center">
                {offer.CodSituacion === '00' ? (
                  !isLoadingOperation ? (
                    <Button onClick={sendToOperation}>Iniciar operación</Button>
                  ) : (
                    <Spinner />
                  )
                ) : null}
              </Layout.Item>
            </Layout>
          </Card>
        </Layout.Item>
      </Layout>
      <Modal
        show={!!operationCode}
        closeModal={() => setOperationCode(null)}
        title={'Operación generada con éxito'}
        id={'__operation_id_modal_' + offer.Code}
      >
        <div className="u-text-center">
          <h5>Nº Operacion: {operationCode}</h5>
          <div>
            <center>
              <Button
                type={'link'}
                text={'Descargar solicitud de crédito'}
                icon={'[document-pdf]'}
                iconPosition={'left'}
                position="center"
                onClick={handleOnClick}
                className={'c-btn--secondary u-mt-small'}
              />
            </center>
          </div>
        </div>
      </Modal>
    </>
  );
};
